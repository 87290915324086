import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const VideoSchema = dynamic(() => import('./HomePageVideoSchema'));

interface HomepageVideoProps {
  videoOptions: any;
  videoAltText: string;
}

function HomepageVideo({ videoOptions, videoAltText }: HomepageVideoProps) {
  const desktopThumbnailUrl = videoOptions?.background_image ? 
    `${videoOptions.background_image.replace('https://cdn.dresden.vision/video/home-video', 'https://w.dresden.vision/img/video/home-video')}.webp?q=60` : '';
  const mobileThumbnailUrl = videoOptions?.mobile_background_image ? 
    `${videoOptions.mobile_background_image.replace('https://cdn.dresden.vision/video/home-video', 'https://w.dresden.vision/img/video/home-video')}.webp?q=40` : '';
  const router = useRouter();

  return (
    <>
      <Link href={videoOptions?.href || '/shop'} className="block cursor-pointer">
        <VideoSchema
          videoAltText={videoAltText}
          desktopThumbnailUrl={desktopThumbnailUrl}
          mobileThumbnailUrl={mobileThumbnailUrl}
          desktopVideoUrl={videoOptions?.background_video}
        />
        <div className="relative w-full pt-[124.80%] md:hidden">
          {videoOptions?.mobile_background_video ? (
            <video
              playsInline
              autoPlay
              muted
              loop
              poster={mobileThumbnailUrl}
              className="absolute top-0 left-0 w-full h-full object-cover"
            >
              <source src={videoOptions.mobile_background_video} type="video/mp4" />
              <meta itemProp="description" content={videoAltText} />
            </video>
          ) : videoOptions?.mobile_background_image && (
            <Image
              src={videoOptions.mobile_background_image}
              alt={videoAltText}
              fill
              className="absolute top-0 left-0 w-full h-full object-cover"
              priority
            />
          )}
        </div>
        <div className="relative w-full pt-[40.00%] hidden md:block">
          {videoOptions?.background_video ? (
            <video
              playsInline
              autoPlay
              muted
              loop
              poster={desktopThumbnailUrl}
              className="absolute top-0 left-0 w-full h-full object-cover"
            >
              <source src={videoOptions.background_video} type="video/mp4" />
              <meta itemProp="description" content={videoAltText} />
            </video>
          ) : videoOptions?.background_image && (
            <Image
              src={videoOptions.background_image}
              alt={videoAltText}
              fill
              className="absolute top-0 left-0 w-full h-full object-cover"
              priority
            />
          )}
        </div>
      </Link>
    </>
  );
}

export default HomepageVideo;