import React, { useEffect, useState } from 'react';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import LazyHydrate from 'react-lazy-hydration';
import { useDispatch } from 'react-redux';
import HomepageVideo from 'components/Homepage/HomepageVideo';

const HomepageUniqueSellingPoints = dynamic(
  () => import('components/Homepage/HomepageUniqueSellingPoints')
);
const HomepageStoreContent = dynamic(
  () => import('components/Homepage/HomepageStoreContent')
);
const HomepageReviews = dynamic(
  () => import('components/Homepage/HomepageReviews')
);
const RecommendedProductsSlider = dynamic(
  () => import('components/Others/RecommendedProductsSlider')
);
const HomepageAcceptedHealthFunds = dynamic(
  () => import('components/Homepage/HomepageAcceptedHealthFunds')
);
const CommunityShowcase = dynamic(() => import('components/ProductDetail/CommunityShowcase'), { ssr: false });

import { getHomePageId } from '../constants/woocommerceParentCateoryIds';
import { handleregionapollo } from '../functions/handleregionapollo';
import { GET_HOMEPAGE } from '../gqlStrings/queries/getHomepage';
import { setIsNavbarBlack } from '../redux/features/auth/authSlice';
import HomepageCategoriesV2 from 'components/Homepage/HomapageCategoriesV2';
import useDeviceType from 'hooks/useDeviceType';
import notionStoreDataService from 'services/notion.stores.service';
import { NotionStore, StoreCountry, StoreState } from 'constants/store.dataType';
import { getSeoMetaTags } from 'utils/common.utils';
import { SeoMetaTags } from 'constants/dataTypes';
import GlobalSeoMetaTags from 'components/GlobalSeoMetaTags/GlobalSeoMetaTags';
import HomepageCampaignBanners, { PromoBannersObject } from 'components/Homepage/HomepageCampaignBanners';
import { title } from 'node:process';
import { getWelcomeCampaing } from 'constants/welcomeCampaing';
import { getPageBanners } from 'utils/common.utils';

interface RegionVideo {
  desktop: string;
  mobile: string;
  href: string;
}

interface RegionVideos {
  primary: RegionVideo;
  secondary?: RegionVideo;  // Make secondary optional
}

export default function Home({
  page,
  stores,
  seoMetaTags,
  videoOptions,
  videoAltText,
  promoBanners
}: {
  page: any;
  stores: any;
  seoMetaTags: SeoMetaTags;
  videoOptions: any;
  videoAltText: string;
  promoBanners: PromoBannersObject;
}) {
  console.log('videoOptions', videoOptions);
  // Randomly select a video on each page load
  // useEffect(() => {
  //   if (videoOptions.secondary) {
  //     setSelectedVideo(Math.random() < 0.5 ? videoOptions.primary : videoOptions.secondary);
  //   } else {
  //     setSelectedVideo(videoOptions.primary);
  //   }
  // }, [videoOptions]);

  const dispacth = useDispatch();
  const deviceType = useDeviceType();
  const thumbnailUrl = videoOptions && deviceType.isMobile
    ? `https://w.dresden.vision/img/video/home-video.webp?q=40`
    : `https://w.dresden.vision/img/video/home-video.webp?q=60`;

  useEffect(() => {
    dispacth(setIsNavbarBlack(page.homePageField.navbarMenuColorIsBlack));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Dresden Vision | Glasses Made Simple</title>
        <meta name="description" content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250." />
        <meta property="og:description" content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250." />
        <meta property="og:image" content="https://cdn.dresden.vision/static-images/dresdenvision.webp" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="twitter:image" content="https://cdn.dresden.vision/static-images/dresdenvision.webp" />
        <meta name="twitter:description" content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250." />
        <link rel="preload" href={thumbnailUrl} as="image" type="image/webp" />


      </Head>
      <HomepageVideo
        videoOptions={videoOptions}
        videoAltText={videoAltText}
      />
      <HomepageCampaignBanners promoBanners={promoBanners} />
      <HomepageCategoriesV2 />
      <LazyHydrate whenVisible>
        <HomepageStoreContent stores={stores} />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <HomepageUniqueSellingPoints />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <CommunityShowcase />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <HomepageReviews />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <RecommendedProductsSlider />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <HomepageAcceptedHealthFunds />
      </LazyHydrate>
      {/* <GlobalSeoMetaTags seoMetaTags={seoMetaTags} /> */}
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { data } = await handleregionapollo(locale).query({
    query: GET_HOMEPAGE,
    variables: {
      id: getHomePageId(locale)
    },
    context: { region: locale }
  });

  const stores = await notionStoreDataService.getStores();
  //console.log('storess', JSON.stringify(storess));


  const allstores =
    locale === 'ca'
      ? stores.reverse()
      : stores;


  allstores.forEach((country: any) => {
    country.states.forEach((state: any) => {
      state.stores = state.stores.filter((store: any) => store.slug !== "brunswick");
    });
  });

  const countryList: any[] = [];
  allstores.map((countryData: StoreCountry) => {
    const stateList: any[] = [];
    countryData.states.map((stateData: StoreState) => {
      const storeList: any[] = [];
      stateData.stores.map((store: NotionStore) => {
        storeList.push({
          "name": store.name,
          "path": "/stores/" + store.slug,
          "googleReviewUrl": store.googleReviewLink
        });
      });

      stateList.push({
        "code": stateData.state.name,
        "name": stateData.state.name,
        "path": '/stores/' + stateData.state.slug,
        "storeList": storeList
      });
    })
    countryList.push({
      "code": countryData.country.name.substring(0, 2).toUpperCase(),
      "name": countryData.country.name,
      "stateList": stateList
    });
  })

  if (!data) {
    return {
      notFound: true
    };
  }

  const seoMetaTags = await getSeoMetaTags('/index', locale);

  // Get page banners
  const pageBanners = await getPageBanners('index', locale);

  // Get video options from page banners if available, otherwise use default
  const videoOptions = pageBanners?.page_banners?.find(banner => banner.type === 'videosByRegion');

  // Get video alt text based on locale
  const videoAltText = locale === 'au' ?
    "Video presents stylish and customisable frames for glasses for Australian customers. These frames for glasses come in many colours and have interchangeable arms. Available in various sizes to suit everyone. These Australian-made frames come with a 10-year warranty, offering long-lasting durability and a personalised touch. Choose from a wide range of vibrant frame colours and enjoy clear vision with frames designed for your style. Get your trendy pair at Dresden Vision and experience the versatility of glasses with interchangeable arms that suit any face or look." :
    locale === 'nz' ?
      "Video presents fashionable, Australian-made frames for glasses for customers in New Zealand. These frames are customisable and have interchangeable arms. They also come in all sorts of vibrant colours and sizes. Whether you need frames for every occasion or want to make a bold fashion statement, we offer trendy glasses with a 10-year warranty. Find your perfect fit with our colourful, stylish frames and see the world clearer. Get your pair of Dresden Vision Frames today—crafted for everyone's unique style and comfort." :
      locale === 'ca' ?
        "Video presents stylish and customisable frames for glasses for Canadian customers. With a range of colourful frames and interchangeable arms in various sizes, these frames are of high-quality craftsmanship. Offering a perfect fit for everyone, these durable frames are backed by a 10-year warranty. See the world clearer with Dresden Vision eyewear. Elevate your style with modern, custom frames, and get your pair today." :
        "The video showcases stylish, customisable glasses frames designed for all. These frames come in a variety of colors, features and interchangeable arms, offering a personalised touch. Available in multiple sizes to fit everyone, these frames are built to last with a 10-year warranty. Explore a vibrant selection of frame colors and enjoy clear vision with frames tailored to your style. Visit Dresden Vision online to find your trendy pair and experience the versatility of Dreden Vision Frames for glasses to suit any face or style";

  // Transform page banners into promo banners format
  const promoBanners = {
    firstOrderBanner: pageBanners?.page_banners?.find(banner => banner && banner.type === 'firstOrderBanner') || null,
    nonFirstOrderBanner: pageBanners?.page_banners?.find(banner => banner && banner.type === 'nonFirstOrderBanner') || null,
    mixMatchBanner: pageBanners?.page_banners?.find(banner => banner && banner.type === 'mixMatchBanner') || null,
    promotionBanners: pageBanners?.page_banners?.filter(banner => banner && banner.type === 'promotionBanners') || null
  };

  return {
    props: {
      page: data.page,
      stores: countryList,
      seoMetaTags,
      videoOptions,
      videoAltText,
      promoBanners
    }
  };
};
